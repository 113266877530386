<template>
  <div class="official-container">
    <div class="official-top">
      <h4>机房考试</h4>
    </div>
    <div class="search">
      <div class="left">
        <el-select class="search-select" v-model="provinceId" placeholder="省" @change="getCity" clearable>
          <el-option v-for="(item, index) in provinceList" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <el-select class="search-select" v-model="cityId" placeholder="市" @change="getDistrict" clearable>
          <el-option v-for="(item, index) in cityList" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <el-select class="search-select" v-model="districtId" placeholder="区" clearable>
          <el-option v-for="(item, index) in districtList" :key="index" :label="item.name"
                     :value="item.id"></el-option>
        </el-select>
        <el-select class="search-select" v-model="gradeId" placeholder="学段" clearable>
          <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <el-autocomplete
            v-model="searchName"
            :fetch-suggestions="querySearch"
            placeholder="请输入学校名称搜索"
            :trigger-on-focus="false"
            clearable
            style="width: 250px;"></el-autocomplete>
      </div>
      <div class="btn">
        <el-button type="primary" @click="search">查询</el-button>
      </div>
    </div>

    <div class="school-list">
      <template v-for="item of list">
        <div class="school-list__item" :key="item.schoolId">
          <div class="school-list__item-left">
            <h6 class="school-list__item-left__name">{{ item.schoolName }}</h6>
            <p class="school-list__item-left__desc">
              <span style="margin-right: 10px;">{{ item.sectionName }}</span>
              {{ item.provinceName }}-{{ item.cityName }}-{{ item.countyName }}
            </p>
          </div>
          <p class="school-list__item-time">最近考试：{{ item.lastTime || '-' }}</p>
          <div class="school-list__item-jump"
               @click="getExamList(item)">
            <span>进入学校</span>
            <img class="school-list__item-jump-arrow" src="../../assets/icon-arrow-right-blue.png" alt="copy"/>
          </div>
        </div>
        <div class="school-list__item-line"></div>
      </template>
    </div>

    <div class="pagination" v-if="totalCount>20">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="totalCount">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// copy element-ui 的 backtop 代码
import {getOfficialSchoolList} from "@/api/official";

const cubic = value => Math.pow(value, 3);
const easeInOutCubic = value => value < 0.5
    ? cubic(value * 2) / 2
    : 1 - cubic((1 - value) * 2) / 2;

export default {
  name: "officialExam",
  data() {
    return {
      loading: false,
      searchName: '',
      searchId: '',
      provinceList: [],
      provinceId: null,
      cityList: [],
      cityId: null,
      districtList: [],
      districtId: null,
      gradeList: [{
        id: 1,
        name: '初中'
      }, {
        id: 2,
        name: '高中'
      }],
      gradeId: null,
      searchSchoolList: [],
      list: [],
      pageIndex: 1,
      pageSize: 20,
      totalCount: 0
    }
  },
  watch: {
    searchName(val) {
      const {searchSchoolList} = this
      let searchId = ''
      for (let i = 0, len = searchSchoolList.length; i < len; i += 1) {
        if (val === searchSchoolList[i].schoolName) {
          searchId = searchSchoolList[i].schoolId
          break
        }
      }
      this.searchId = searchId
    }
  },
  created() {
    const superAdmin = sessionStorage.getItem('superAdmin')
    if (superAdmin === '2') {
      this.findSchoolListByTeacherId()
      this.getSchoolList()
      this.getProvince()
    } else {
      this.$router.replace({name: 'officialList'})
    }
  },
  activated() {
    const superAdmin = sessionStorage.getItem('superAdmin')
    if (superAdmin !== '2') {
      this.$router.replace({name: 'officialList'})
    }
  },
  methods: {
    handleSizeChange(val) {
      this.pageIndex = val
      this.findSchoolListByTeacherId()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.findSchoolListByTeacherId()
    },
    getExamList(item) {
      const {schoolId, schoolName, provinceId, section} = item
      this.$router.push({
        name: 'officialList',
        query: {
          section,
          provinceId,
          schoolId,
          name: schoolName
        }
      })
    },
    // 获取省
    getProvince() {
      this.$axios({
        method: 'post',
        url: this.$urls.cityList,
        data: {
          gradeTypeId: 8
        }
      }).then((response) => {
        if (response.state === '11') {
          this.provinceList = response.data
        }
      })
    },
    // 获取市
    getCity() {
      this.cityList = []
      this.cityId = null
      this.districtId = null
      this.districtList = []
      this.$axios({
        method: 'post',
        url: this.$urls.cityGet,
        data: {
          pid: this.provinceId
        }
      }).then((response) => {
        if (response.state === '11') {
          this.cityList = response.data
        }
      })
    },
    // 获取区
    getDistrict() {
      this.districtId = null
      this.districtList = []
      this.$axios({
        method: 'post',
        url: this.$urls.cityGet,
        data: {
          pid: this.cityId
        }
      }).then((response) => {
        if (response.state === '11') {
          this.districtList = response.data
        }
      })
    },
    getSchoolList() {
      this.$axios({
        method: 'post',
        url: this.$urls.getSchoolList
      }).then((response) => {
        if (response.state === '11') {
          const {data} = response
          for (let i = 0, len = data.length; i < len; i += 1) {
            data[i].value = data[i].schoolName
          }
          this.searchSchoolList = data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    querySearch(queryString, cb) {
      const {searchSchoolList} = this
      const list = searchSchoolList.filter(this.schoolFilter(queryString))
      cb(list)
    },
    schoolFilter(name) {
      return (school) => {
        return (school.schoolName.indexOf(name) !== -1)
      }
    },
    search() {
      this.pageIndex = 1
      this.findSchoolListByTeacherId()
    },
    async findSchoolListByTeacherId() {
      this.loading = true
      const form = {}
      if (this.searchId !== '') form.schoolId = this.searchId
      if (this.provinceId) form.provinceId = this.provinceId
      if (this.cityId) form.cityId = this.cityId
      if (this.districtId) form.countyId = this.districtId
      if (this.gradeId) form.section = this.gradeId
      const {pageIndex} = this
      const {pageSize} = this
      try {
        const res = await getOfficialSchoolList(form, pageIndex, pageSize)
        if (res.state === '11') {
          const {total, pageContents} = res.data
          this.list = pageContents
          this.totalCount = total
          this.scrollToTop()
          this.$store.commit('includeAdd', 'official')
        }
      } catch (e) {
      }
      this.loading = false
    },
    /**
     * ElementUI 的 backtop 代码
     */
    scrollToTop() {
      const el = document.querySelector('#app');
      const beginTime = Date.now();
      const beginValue = el.scrollTop;
      const rAF = window.requestAnimationFrame || (func => setTimeout(func, 16));
      const frameFunc = () => {
        const progress = (Date.now() - beginTime) / 500;
        if (progress < 1) {
          el.scrollTop = beginValue * (1 - easeInOutCubic(progress));
          rAF(frameFunc);
        } else {
          el.scrollTop = 0;
        }
      };
      rAF(frameFunc);
    }
  }
}
</script>

<style lang="scss" scoped>
.official-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 84px;
  color: #333333;
  font-size: 20px;
  padding: 0 28px;
}

.search {
  @include flex(space-between);
  height: 56px;
  background-color: #FFFFFF;
  padding: 0 28px;
  margin-bottom: 1px;

  .left {
    @include flex;

    .search-select {
      width: 140px;
      margin-right: 10px;
    }

    .search-name {
      width: 220px;
    }
  }

  .btn {
    padding-left: 28px;
    border-left: 1px solid #E5E5E5;

    button {
      width: 100px;
      height: 36px;
      font-size: 15px;
      border: none;
      background: linear-gradient(90deg, #6DBBFC 0%, #309AF2 100%);

      &:hover {
        background: linear-gradient(90deg, #309AF2 0%, #309AF2 100%);
      }
    }
  }
}

.school-list {
  min-height: calc(100vh - 174px);
  background-color: #FFFFFF;
  padding: 14px 28px;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 28px;

    &:hover {
      box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.08);
      border-radius: 8px;
    }

    &-left {
      flex: 1;
      &__name {
        color: #333333;
        font-size: 17px;
      }

      &__desc {
        color: #999999;
        font-size: 15px;
        margin-top: 12px;
      }
    }

    &-time {
      color: #999999;
      font-size: 15px;
      width: 230px;
      text-align: left;
      margin-right: 100px;
    }

    &-jump {
      display: flex;
      align-items: center;
      width: 112px;
      height: 40px;
      color: #309AF2;
      font-size: 15px;
      border-radius: 4px;
      border: 1px solid #309AF2;
      cursor: pointer;
      padding: 0 14px;

      &:hover {
        background-color: #eaf5fe;
      }

      &-arrow {
        width: 14px;
        height: 14px;
        margin-left: 8px;
      }
    }

    &-line {
      height: 1px;
      background-color: #F1F1F1;
      margin: 0 28px;
    }
  }
}

.pagination {
  text-align: center;
  background-color: #FFFFFF;
  padding: 10px;
}
</style>
